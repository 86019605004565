import React from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
// import Seo from '../components/seo/seo';

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Seo
        canonical={`https://www.essentialbehavioral.com/privacy-policy/`}
      />

      <>
        <section className={`py-5`}>
          <Container>
            <Row className={`justify-content-center`}>
              <Col xs={11} md={8}>
                <h1>Privacy Policy</h1>
                <p>Clean &amp; Sober Detox operates the <a href="https://www.cleanandsoberdetox.com/">https://www.cleanandsoberdetox.com/</a> website, which provides the adult addiction treatment services.</p>
                <p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
                <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at <a href="https://www.cleanandsoberdetox.com/">https://www.cleanandsoberdetox.com/</a>, unless otherwise defined in this Privacy Policy.</p>
                <p><strong>Information Collection and Use</strong><br/>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. We are the sole owners of the information collected on the site. The information that we collect will be used to contact or identify you. We never sell your personal information to third parties.</p>
                <p>We also collect non-personally identifiably information through such devices like analytics, cookies, and log files, which may include, but is not limited to, your IP (internet protocol) address, your ISP (internet service provider), the Web browser you used to visit the Site, the time visited the Site, which Web Pages you visited on the Site, and other anonymous Site usage data.</p>
                <p>We may share information with governmental agencies or other companies assisting us in fraud prevention or investigation. Under no circumstances do we rent, trade or&nbsp;<em>share</em>&nbsp;your address or e-mail address with&nbsp;<em>any</em>&nbsp;other company for&nbsp;<em>their</em>&nbsp;marketing purposes&nbsp;<em>without</em>&nbsp;your consent. We may do so when: (1) permitted or required by law; or (2) trying to protect against or prevent actual or potential fraud or unauthorized transactions; or (3) investigating fraud which has already taken place. The information is not provided to these companies for marketing purposes.</p>
                <p><strong>Log Data</strong><br/>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer Internet Protocol address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>
                <p><strong>Cookies</strong><br/>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.&nbsp; Cookies can also enable us to track and target the interests of our users to enhance the experience on our site.<br/>Our website uses these cookies to collection information and to improve our Service. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.&nbsp; Usage of a cookie is in no way linked to any personally identifiable information on our site.</p>
                <p><strong>Service Providers</strong><br/>We may employ third-party companies and individuals due to the following reasons:<br/>• To facilitate our Service;<br/>• To provide the Service on our behalf;<br/>• To perform Service-related services; or<br/>• To assist us in analyzing how our Service is used.</p>
                <p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
                <p><strong>Security</strong><br/>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. We take necessary precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.<br/>Wherever we collect sensitive information, that information is encrypted and transmitted to us in a secure way. You can verify this by looking for “https” at the beginning of the address of the web page. While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment. The safety and security of your information also depends on you. We urge you to be careful about giving out information in public areas of the Site like message boards. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your Personally Identifiable Information, you acknowledge that: (a) there are security and privacy limitations of the Internet which are beyond our control; (b) the security, integrity and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed; and (c) any such information and data may be viewed or tampered with in transit by a third party.</p>
                <p><strong>Links to Other Sites</strong><br/>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                <p><strong>Children’s Privacy</strong><br/>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
                <p><strong>Changes to This Privacy Policy</strong><br/>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>
                <p><strong>Contact Us</strong><br/>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us. If you feel that we are not abiding by this privacy policy, you should contact us immediately.</p>
                <p><strong>NOTICE OF PRIVACY PRACTICES</strong></p>
                <p><em>THIS NOTICE DESCRIBES HOW INFORMATION ABOUT YOU AND YOUR TREATMENT MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</em></p>
                <p>This organization is required by law to maintain the privacy and confidentiality of your health information and to provide you with notice of its legal duties and privacy practices with respect to your health information. Please also respect the privacy of others you encounter in treatment.</p>
                <p><strong>General Information:</strong></p>
                <p>Information regarding your health care, including payment for health care, is protected by two federal laws: the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”), 42 U.S.C. &amp; 1320d et seq., 45 C.F.R. Parts 160 &amp; 164, and the Confidentiality Law, 42 U.S.C &amp; 290dd-2, 42 C.F.R. Part 2. Under these laws, Clean &amp; Sober Detox may not say to a person outside Clean &amp; Sober Detox that you attend the program, nor may Clean &amp; Sober Detox disclose any information except as permitted by federal law.</p>
                <p>Clean &amp; Sober Detox must obtain your written consent before it can disclose information about you for payment process. For example, Clean &amp; Sober Detox must obtain your written consent before it can disclose information to your health insurer in order to be paid for services. Generally, you must also sign a written consent before Clean &amp; Sober Detox can share information for treatment purposes or for health care operations. However, federal law permits Clean &amp; Sober Detox to disclose information without your written permission:</p>
                <ol>
                  <li>Pursuant to an agreement with a Qualified Service Organization;</li>
                  <li>For research, audit or evaluations;</li>
                  <li>To report a crime committed on Clean &amp; Sober Detox’s premises or against Clean &amp; Sober Detox personnel;</li>
                  <li>To medical personnel in a medical emergency;</li>
                  <li>To appropriate authorities to report suspected child abuse or neglect;</li>
                  <li>As allowed by a court order.</li>
                </ol>
                <p>For example, Clean &amp; Sober Detox can disclose information without your consent to obtain legal or financial services, or to another medical facility to provide health care to you, as long as there is a Qualified Service Organization Agreement in place.&nbsp; Before Clean &amp; Sober Detox can use or disclose any information about your health in a manner which is not described above, it must obtain your specific written consent allowing it to make the disclosure. Any such written consent may be revoked by you either verbally in-person or in writing.</p>
                <p>Clean &amp; Sober Detox collects health information about you and stores it in an electronic health record. This is your medical record. The medical record is the property of Clean &amp; Sober Detox, however, the information in the medical record belongs to you. The Health Insurance Portability and Accountability Act (HIPAA) requires our agency to maintain the privacy of your medical record. HIPAA generally requires that any uses or disclosures of information in your medical record be limited to the minimum necessary to the purposes of the uses or disclosures. HIPAA also provides you certain rights with respect to the information in your medical record which are described below.</p>
                <p>Information relating to your treatment at is protected by federal regulations specific to drug and alcohol treatment, which are known as 42 CFR Part 2. These regulations protect the confidentiality of information relating to the identity, diagnosis, prognosis, or treatment of any person in a drug or alcohol treatment program. &nbsp;Clean &amp; Sober Detox may not disclose records relating to your treatment without your written consent, except in narrowly limited circumstances. Under 42 CFR Pt 2, the terms of a written consent to disclose information must specify the scope and types of information to be disclosed, the parties to whom the information may be disclosed, the purpose of the disclosure and the timeframe of the consent. You may revoke a consent to disclose information relating to drug and alcohol verbally or in writing&nbsp;at any time.</p>
                <p>At Clean &amp; Sober Detox, we ask for your written consent to disclose treatment information for certain purposes, including releasing treatment information to or obtaining information from your other medical providers, obtaining payment from insurance or other payors, contacting your family and/or support persons either for treatment purposes or in the case of a medical or other emergency. &nbsp;Clean &amp; Sober Detox will not disclose your treatment information for these purposes without your written consent.</p>
                <p>Clean &amp; Sober Detox may disclose treatment information without your written consent under certain narrow circumstances as permitted by 42 CFR Part 2. For treatment purposes, Clean &amp; Sober Detox is permitted to use and disclose treatment information internally and to entities with which it shares administrative control. &nbsp;Clean &amp; Sober Detox staff may disclose treatment information to outside auditors, regulatory agencies, and evaluators and for certain research purposes. Clean &amp; Sober Detox may disclose treatment information without your written consent when necessary in a life-threatening medical emergency and may disclose to report a crime on the premises or against personnel. Lastly, information may be disclosed without consent where the state mandates child abuse and neglect reporting; when cause of death is being reported; or when required by a valid court order that contains specific required findings. Our Alumni staff may contact you to share information about your treatment experience or to send you reminder notices of future events.</p>
                <p><strong>Your Health Information Rights<br/></strong>In addition to protecting privacy and confidentiality, HIPAA and 42 CFR Part 2 afford you the following rights with respect to your medical record and drug or alcohol treatment information:</p>
                <ol>
                  <li>You have the right to a paper copy of this written notice of the agency’s privacy practices.</li>
                  <li>You have a right to request a copy of your treatment record or to receive your health information through a reasonable alternative means or at an alternative location. The agency requires that all such requests be put in writing. A reasonable fee will be charged for copying your health information.</li>
                  <li>You have a right to request that the agency amends health information that is incorrect or incomplete. If the agency determines not to amend the health information, it will provide you with an explanation of the reason for the denial and your rights to disagree with the denial.</li>
                  <li>You have a right to request restrictions on otherwise permitted uses and disclosures of your health information. The agency is not obligated to comply with such requests.</li>
                  <li>You may request that we provide you with a written accounting of all disclosures made by us during a specific time period (not to exceed 6 years). We ask that such requests be made in writing on a form provided by our facility. Please note that an accounting will not apply to any of the following types of disclosures: disclosures made with your written consent for reasons of treatment, payment or health care operations; disclosures made to you or your legal representative, or any other individual involved with your care. You will not be charged for your first accounting request in any 12-month period. However, for any requests that you make thereafter, you will be charged a reasonable, cost-based fee</li>
                  <li>Your health information cannot be given to your employer, used or shared for things like sales calls or advertising, unless you give your permission by signing a consent form. You must cancel your authorization in writing. The authorization form will give you instructions on where to send the written notice or will direct you to another place to find this information.</li>
                  <li>You have the right to correct any mistakes on your health information. Any request to change a health record must be submitted in writing.</li>
                  <li>You have a right to revoke a consent to one or more parties and leave the rest of the consent in effect.</li>
                </ol>
                <p><strong>Changes to this Notice of Privacy Practices<br/></strong>We reserve the right to improve this Notice of Privacy Practices at any time moving forward and&nbsp; make the new provisions effective for all information that it maintains, including information that was created or received prior to the date of such modification. Until amendment is made, we remain in compliance with this Notice as required by law. If our privacy practices change, we will provide all current and forthcoming clients with a copy of the revised Notice of Privacy Practices</p>
                <p><strong>Complaints Regarding Privacy Practices<br/></strong>Complaints about this Notice of Privacy Practices and/or handling your health information should be directed to:</p>
                <p><strong>VP of Clinical and Compliance Services</strong></p>
                <p><strong>Sprout Health Group</strong></p>
                <p><strong>3 Corbett Way</strong></p>
                <p><strong>Eatontown, New Jersey 07724 </strong></p>
                <p>If you are not satisfied in manner of which this office handles a complaint, you may submit a formal complaint to one of the regional U.S Department of Health and Human Services Offices for Civil Rights. A list of these offices can be found online at:&nbsp;<a href="https://www2.ed.gov/about/offices/list/ocr/addresses.html">https://www2.ed.gov/about/offices/list/ocr/addresses.html</a></p>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 3 }) {
      title
      seo {
        metaTitle
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      content
    }
  }
`;

export default PrivacyPolicyPage;
